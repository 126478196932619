import { createContext, useContext, useEffect, useMemo, useState } from "react";

type Theme = "dark" | "light" | "system";

type ThemeProviderProps = {
    children: React.ReactNode;
    defaultTheme?: Theme;
    storageKey?: string;
};

type ThemeProviderState = {
    theme: Theme;
    current: "dark" | "light";
    setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
    theme: "system",
    current: "dark",
    setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
    children,
    defaultTheme = "system",
    storageKey = "vite-ui-theme",
    ...props
}: ThemeProviderProps) {
    const [theme, setTheme] = useState<Theme>(() => {
        if (typeof sessionStorage !== "undefined") {
            return (sessionStorage.getItem(storageKey) ?? defaultTheme) as Theme;
        }
        return defaultTheme;
    });
    const [system, setSystem] = useState<"dark" | "light">("light");

    useEffect(() => {
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === "attributes") {
                    const classList = (mutation.target as HTMLElement).classList;

                    if (!classList.contains(system)) {
                        setSystem(system === "dark" ? "light" : "dark");
                        // console.log("System theme changed to", system);
                    }
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true, //configure it to listen to attribute changes
            attributeFilter: ["class"], //configure it to listen to class changes
            subtree: false,
        });

        return () => {
            observer.disconnect();
        };
    }, [system]);

    useEffect(() => {
        const root = window.document.documentElement;

        root.classList.remove("light", "dark");

        if (theme === "system") {
            const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
            setSystem(systemTheme);
            root.classList.add(systemTheme);
            return;
        } else {
            setSystem(theme);
        }

        root.classList.add(theme);
    }, [theme]);

    const value = useMemo(() => {
        return {
            theme,
            current: system,
            setTheme: (updated: Theme) => {
                sessionStorage.setItem(storageKey, updated);
                setTheme(updated);
            },
        };
    }, [theme, system]);

    return (
        <ThemeProviderContext.Provider {...props} value={value}>
            {children}
        </ThemeProviderContext.Provider>
    );
}

export const useTheme = () => {
    const context = useContext(ThemeProviderContext);

    if (context === undefined) throw new Error("useTheme must be used within a ThemeProvider");

    return context;
};
