/* eslint-disable @typescript-eslint/no-explicit-any */
import { WhitelabelContext } from "@/whitelabel/Whitelabel";
import { useMemo, type PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import "./index.css";

function Logo(props: { variant: "login-quote" | "login" | "header-logo" | "header-logo-text"; className?: string }) {
    // @ts-expect-error "voixoo is dynamic here"
    const { t } = useTranslation(["vozuu"]);

    if (props.variant === "header-logo") {
        return <img src={`/voixoo/logo.svg`} alt="Logo" className="h-[36px]" />;
    }

    if (props.variant === "header-logo-text") {
        return (
            <div className="flex flex-row items-center space-x-4">
                <img src={`/voixoo/logo.svg`} alt="Logo" className="h-[36px]" />
                <div className="tracking-tight text-[#b5532e] text-xl pb-1">vozuu</div>
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-row items-center space-x-4">
                <img src={`/voixoo/logo.svg`} alt="Logo" className="h-12 lg:h-16" />
                <h1 className="tracking-tight text-5xl text-[#b5532e] pb-2">vozuu</h1>
            </div>
            {props.variant === "login-quote" && (
                <blockquote className="mt-6 border-l-2 pl-6 italic text-muted-foreground md:max-w-lg text-justify space-y-1">
                    <p>
                        <strong>{t("Logo.tagline", "Reimagine Surveys. Empower Decisions.")}</strong>
                    </p>
                    <p>
                        {t(
                            "Logo.hero",
                            `The next-generation survey platform that blends simplicity with powerful workflows and analytics.
                            Whether you're gathering customer feedback, conducting employee engagement surveys, or
                            performing market research, our cutting-edge tools make it effortless to collect, share, and
                            analyze insights in real-time.`
                        )}
                    </p>
                    <div className="text-right pt-2">
                        <p>ChatGPT</p>
                        <p>biggest tech influencer, 2024</p>
                    </div>
                </blockquote>
            )}
        </>
    );
}

export default function Whitelabel(props: PropsWithChildren) {
    const value = useMemo(() => {
        return {
            assetsPrefix: "/vozuu",
            translation: "vozuu" as any,
            marketingName: "vozuu",
            Logo,
        };
    }, []);

    return (
        <WhitelabelContext.Provider value={value}>
            <Helmet>
                <title>vozuu</title>
                <link rel="icon" href="/vozuu/favicon.ico" />
            </Helmet>
            {props.children}
        </WhitelabelContext.Provider>
    );
}
