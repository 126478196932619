import { WhitelabelContext } from "@/whitelabel/Whitelabel";
import { useMemo, type PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";

import "./index.css";

function Logo(props: { variant: "login-quote" | "login" | "header-logo" | "header-logo-text"; className?: string }) {
    if (props.variant === "header-logo") {
        return <img src={`/stackables/logo.svg`} alt="Logo" className="h-8" />;
    }

    if (props.variant === "header-logo-text") {
        return (
            <div className="flex flex-row items-center space-x-4">
                <img src={`/stackables/logo.svg`} alt="Logo" className="h-8" />
                <div className="tracking-tight  text-[#324053] text-xl">Stackables Cloud</div>
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-row items-center space-x-4">
                <img src={"/stackables/logo.svg"} alt="Logo" className="h-12 lg:h-16" />

                <h1 className="scroll-m-20 text-3xl font-bold tracking-tight md:text-5xl text-[#324053]">
                    Stackables Cloud
                </h1>
            </div>
            {props.variant === "login-quote" && (
                <blockquote className="mt-6 border-l-2 pl-6 italic text-muted-foreground md:max-w-lg text-justify space-y-1">
                    <p>
                        "I always start projects with modelling the data in Stackables, then building a UI prototype
                        with GraphQL api. No time wasted on databases and back-office.
                    </p>
                    <p>
                        After we are happy with the prototype its easy to lock down the access. This workflow has
                        literally saved us years of wasted work."
                    </p>
                    <div className="text-right pt-2">
                        <p>Aarne Laur</p>
                        <p>tech enthusiast, builder</p>
                    </div>
                </blockquote>
            )}
        </>
    );
}

export default function Whitelabel(props: PropsWithChildren) {
    const value = useMemo(() => {
        return {
            assetsPrefix: "/stackables",
            Logo,
        };
    }, []);

    return (
        <WhitelabelContext.Provider value={value}>
            <Helmet>
                <title>Stackables Cloud</title>
                <link rel="icon" href="/stackables/favicon.ico" />
            </Helmet>
            {props.children}
        </WhitelabelContext.Provider>
    );
}
