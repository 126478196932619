import { AuthContextProvider, SecureArea } from "@/components/AuthContext";
import { DialogContextProvider } from "@/components/DialogContextProvider";
import { FloatingFullscreenDialog } from "@/components/FloatingFullscreenDialog";
import { FullScreenLoader } from "@/components/FullScreenLoader";
import { DeviceLoginCard } from "@/components/login/DeviceLoginCard";
import { PortalHeader } from "@/components/PortalHeader";
import { PullToRefresh } from "@/components/PullToRefresh";
import { UrqlProviderContext } from "@/components/UrqlProviderContext";
import { Suspense } from "react";
import { Outlet, useLocation } from "react-router";

function LoginPage() {
    return (
        <FloatingFullscreenDialog quote disableLoader>
            <DeviceLoginCard />
        </FloatingFullscreenDialog>
    );
}

export default function Dashboard() {
    return (
        <AuthContextProvider>
            <UrqlProviderContext url="/graphql-new">
                <DashboardInner />
            </UrqlProviderContext>
        </AuthContextProvider>
    );
}

function DashboardInner() {
    const location = useLocation();

    // Some routes REALLY are public
    if (location.pathname.startsWith("/-/auth") || location.pathname.startsWith("/-/survey")) {
        return <Outlet />;
    }

    return (
        <SecureArea login={() => <LoginPage />}>
            <PullToRefresh>
                <DialogContextProvider>
                    <div className="flex h-full w-full flex-col">
                        <PortalHeader>
                            <main className="flex flex-1 flex-col gap-4 bg-muted/40 py-4 p-2 xs:p-4 md:gap-8 md:p-10">
                                <Suspense fallback={<FullScreenLoader />}>
                                    <Outlet />
                                </Suspense>
                            </main>
                        </PortalHeader>
                    </div>
                </DialogContextProvider>
            </PullToRefresh>
        </SecureArea>
    );
}
